import styled from "styled-components";
import { ReactSVG } from "react-svg";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";

export const Title = styled(Typography).attrs({
  variant: "body4"
})`
  margin-top: 1.5rem;
  text-align: center;
`;

export const Icon = styled(ReactSVG)`
  svg {
    fill: currentColor;
    height: 3rem;
    width: auto;
  }
`;
