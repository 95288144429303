import React from "react";
import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";

import LazyHydrate from "@components/utils/LazyHydrate";

import * as Styled from "./styles";
import { CenteredIconBoxProps } from "./types";

const CenteredIconBox: React.FC<CenteredIconBoxProps> = ({
  icon,
  title
}) =>
  icon ? (
    <FlexBox flexDirection="column" alignItems="center">
      <LazyHydrate whenVisible>
        <Styled.Icon src={icon} />
      </LazyHydrate>
      {title && <Styled.Title>{title}</Styled.Title>}
    </FlexBox>
  ) : null;

export default CenteredIconBox;
