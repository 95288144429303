import React from "react";
import { storyblokEditable } from "@storyblok/react";

import LazyHydrate from "@components/utils/LazyHydrate";
import CenteredIconBox from "@components/molecules/CenteredIconBox";
import { tryToReplaceDomain } from "@utils/tryToReplaceDomain";

import { CenteredIconBoxStoryblokProps } from "./types";

const CenteredIconBoxStoryblok: React.FC<
  CenteredIconBoxStoryblokProps
> = ({ blok }) => {
  const { icon, title } = blok;

  const item = {
    icon: tryToReplaceDomain(icon.filename),
    title
  };

  return (
    <LazyHydrate
      useDisplayContents={false}
      noWrapper
      whenVisible
      getEventTarget={() => window}
    >
      <CenteredIconBox {...{ ...item, ...storyblokEditable(blok) }} />
    </LazyHydrate>
  );
};

export default CenteredIconBoxStoryblok;
